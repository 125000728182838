.loadingModal {
    width: 335px;
    height: 220px;
    border-radius: 4px;
    background-color: #fff;

    .animatedLoader {
        width: 120px;
        height: 120px;
        display: block;
        margin: 25px auto 15px;
    }
}

.loadingTextWrapper {
    composes: mainFont from "../../style/typography.scss";
    font-size: 22px;
    font-weight: 300;
    color: #666666;
    width: 100%;
    text-align: center;
}
