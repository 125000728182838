.buttonNoOutline {
    outline: none;
    box-shadow: none;
}

.appButton {
    composes: buttonNoOutline;
    border-radius: 40px;
    transition: background 0.2s;
    text-transform: uppercase;
    cursor: pointer;
}

.buttonDisabled:disabled {
    opacity: 0.75;
    border: none;
    cursor: default;
    color: #ffffff;
}

.whiteButtonDisabled:disabled {
    opacity: 0.75;
    border: none;
    cursor: default;
    color: #009fe4;
}

.greyDisabledHover:disabled:hover {
    background: #a7a7a7;
}

.greyHover:hover {
    background: #bebebe;
}

.greenDisabledHover:disabled:hover {
    background: #08a246;
}

.greenHover:hover {
    background: #1ab553;
}

.blueDisabledHover:disabled:hover {
    background: #009fe4;
}

.blueHover:hover {
    background: #33b2e9;
}

.whiteHover:hover {
    border: solid #006f9f 1px;
    color: #006f9f;
}

.linkHover:hover {
    opacity: 0.75;
}

.greyButton {
    composes: appButton buttonDisabled greyDisabledHover greyHover;
    background: #a7a7a7;
    border: solid #707070 1px;
    color: #ffffff;
}

.greenButton {
    composes: appButton;
    background: transparent linear-gradient(103deg, #0ca15d 0%, #0fd179 50%) 0% 0% no-repeat padding-box;
    border: 3px solid #0ec773;
    text-align: center;
    letter-spacing: 1.05px;
    font-size: 14px;
    color: #ffffff;
    outline: none;
    transition: 0.5s;
    background-size: 200% auto;

    &:disabled {
        opacity: 0.55;
        border: none;
        cursor: default;

        &:hover {
            background-position: initial;
        }
    }

    &:hover {
        background-position: right center;
    }
}

.blueButton {
    composes: appButton buttonDisabled blueDisabledHover blueHover;
    border: solid #0062aa 1px;
    background: #009fe4;
    color: #ffffff;
}

.hollowButton {
    composes: appButton;
    composes: secondaryFont from "./typography.scss";
    color: #ffffff;
    border: 3px solid #ffffff;
    background: transparent;

    &:disabled {
        opacity: 0.55;

        cursor: default;

        &:hover {
        }
    }

    &:hover {
        opacity: 0.75;
    }
}

.whiteButton {
    composes: appButton whiteButtonDisabled whiteHover;
    border: solid #009fe4 1px;
    background: #ffffff;
    color: #009fe4;
    text-shadow: none;
}

.appButton__buttonText {
    text-transform: uppercase;
    font-size: 15px;
}

.linkButton {
    composes: secondaryFont from "./typography.scss";
    composes: linkHover;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    color: #ffffff;
    padding: 0;
    margin: 0;
}

.hyperlink {
    composes: secondaryFont from "./typography.scss";
    border: none;
    background: none;
    cursor: pointer;
    color: #0000ee;
    padding: 0;
    margin: 0;
    text-decoration: underline;
}

.closeButton {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}
