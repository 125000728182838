.cartCompactLabel {
    composes: secondaryFont from "../../style/typography.scss";
    color: #777777;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 10px;
}

.form_column_left {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 20px 20px;
}

.form_column_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 10px;
}

.cartCompactForm {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    //margin-top: 23px;
    //margin-bottom: 15px;

    .cartCompact_fieldContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 21px;
    }

    .formError {
        display: flex;
        align-items: center;
        background: #ec002c;
        color: #ffffff;
        min-height: 20px;
        font-size: 14px;
        font-weight: 300;
        padding: 5px 10px;
        border: 1px solid #666666;
        border-radius: 5px;
    }

    .cardCompact_ccInfoContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .arrow_down {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #666666;
    }

    .arrow_down:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #ec002c;
        position: absolute;
        top: -12px;
        left: -10px;
    }

    .arrow_position_bottom {
        position: absolute;
        left: 8px;
        bottom: -12px;
    }
}

.cartCompact_ccNumberContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
}

.cvcContainer {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 145px;
}

.cc_back {
    align-self: flex-start;
    margin-left: 10px;
    margin-top: 5px;
}

.expirationContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 130px;
}

.zipCodeContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 130px;
}

.button_container {
    width: 308px;
    height: 50px;
    align-self: center;
}
