.textField :global .MuiOutlinedInput-root {
    background: white;
}

.textField :global .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #4393cf;
}

.textField :global .MuiFormLabel-root.Mui-focused {
    color: #4393cf;
}

.textField {
    composes: secondaryFont from "../../style/typography.scss";
    font-size: 16px;
    font-weight: 300;
    color: #777777;
    line-height: 19px;
    flex: 1;
}

.fieldContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.createAccountForm {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin: 0 20px;
    max-width: 360px;
}

.dividedButtonContainer {
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
