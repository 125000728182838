.root {
    composes: greenButton from "../style/button.scss";
    composes: secondaryFont from "../style/typography.scss";
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    height: inherit;
    width: inherit;
}

.label {
    height: inherit;
    width: inherit;
}

.contentContainer {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.childrenContainer {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.iconContainer {
    height: inherit;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #008435;
    flex: none;
}
