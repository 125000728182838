.formRoot {
    margin-right: 0px;
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
}

.root.checked {
    color: #007DA0;
}

.label {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 16px;
    font-weight: normal;
    color: #757E95;
    line-height: 24px;
    text-align: left;
}
