.App {
    text-align: center;
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
}

html,
body {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
}
