.existingAccount {
    display: flex;
    flex-direction: column;
    width: 582px;
    padding: 30px 80px;
    box-sizing: border-box;
}

.existingAccount__contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.existingAccount__headerText {
    composes: secondaryFont from "../../style/typography.scss";
    font-size: 22px;
    color: #757e95;
    font-weight: 600;
    line-height: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.existingAccount__subHeaderText {
    composes: secondaryFont from "../../style/typography.scss";
    font-size: 16px;
    color: #757e95;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 30px;
    text-align: center;
}

.existingAccount__manageButton {
    composes: greenButton from "../../style/button.scss";
    height: 40px;
    width: 333px;
    margin-bottom: 20px;
}

.closeButton {
    composes: closeButton from "../../style/button.scss";
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 100;
}

.loginText {
    composes: secondaryFont from "../../style/typography.scss";
    font-size: 16px;
    color: #535865;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 40px;
}
