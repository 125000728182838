.container {
    background: #007da0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.containerBackground_left {
    position: absolute;
    top: 0;
    left: 0;
}
.containerBackground_right {
    position: absolute;
    bottom: 0;
    right: 0;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 24px 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 855px;
}

.title {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 30px;
    font-weight: 300;
    color: #ffffff;
}

.subtitle {
    composes: mainFont from "../style/typography.scss";
    font-size: 22px;
    font-weight: 300;
    color: #ffffff;
}

.body {
    display: flex;
    flex-direction: row;
}

.gutter {
    flex-grow: 1;
}

.card_left {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    width: 530px;
    height: 441px;
    margin-right: 10px;
    padding-top: 5px;
    padding-left: 20px;
    // box-shadow: 0px 19px 20px #00000073;
    border-radius: 14px;
}

.card_right {
    background: #ffffff;
    width: 370px;
    height: 441px;
    margin-left: 10px;
    // box-shadow: 0px 19px 20px #00000073;
    border-radius: 14px;
}

.card_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    // box-shadow: 0px 3px 3px #00000029;
    z-index: 10;
    height: 64px;
}

.card_title {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-weight: 300;
    font-size: 26px;
    line-height: 33px;
    margin-top: 10px;
}

.main_content {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px 24px;
    margin-left: 4px;
    margin-bottom: 4px;
    flex-grow: 1;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    margin-bottom: 20px;
}

.info_title {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
}

.pc {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}

.os {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.os_value {
    composes: secondaryFont from "../style/typography.scss";
    color: #007dba;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.services {
    display: flex;
    flex-direction: column;
    background: #ffffff00;
    border-radius: 14px;
}

.panel_title {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
    margin-top: 0px;
    margin-left: 24px;
}

.panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 24px;
    margin-left: 0;
    margin-top: 20px;
}

.service {
    width: 148px;
    height: 151px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service_title {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
}

.form_container {
    padding: 20px 23px;
    padding-top: 0px;
    margin-top: 0px;
}

.description_text {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 16px;
    font-weight: 400;
    color: #757e95;
    text-align: left;
    margin-bottom: 25px;
    line-height: 24px;
}

.footer {
    composes: secondaryFont from "../style/typography.scss";
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 855px;
    font-size: 10px;
    color: #ffffff;
    text-align: center;
    line-height: 17px;
}

.privacypolicyButton {
    composes: linkButton from "../style/button.scss";
    composes: secondaryFont from "../style/typography.scss";
    font-size: 10px;
    font-weight: 400;
    margin: 0 3px;
}

.termsButton {
    composes: linkButton from "../style/button.scss";
    composes: secondaryFont from "../style/typography.scss";
    font-size: 10px;
    font-weight: 400;
    margin: 0 3px;
}

.backButton {
    composes: blueButton from "../style/button.scss";
    composes: secondaryFont from "../style/typography.scss";
    width: 100px;
    height: 30px;
    padding: 0;
}

.machineImage {
    max-height: 144px;
    max-width: 180px;
}

.horizonalDivider {
    height: 1px;
    width: 92%;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #00000012;
}

.verticalDivider {
    width: 1.5px;
    height: 60%;
    transform: translateY(56px);
    background-color: #00000012;
}
