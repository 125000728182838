.titleBar {
    display: flex;
    background: #edeef3;
    height: 50px;
}

.company {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.icon {
    margin-left: 24px;
    margin-right: 10.5px;
}

.title {
    composes: logoFont from "../style/typography.scss";
    color: #ffffff;
    font-weight: 300;
}

.title__emphasized {
    composes: logoFont from "../style/typography.scss";
    color: #ffffff;
    font-weight: 600;
}

.title__separator {
    color: #ffffff;
    font-size: 18px;
    margin: 0 3px;
}

.actions {
    display: flex;
}
