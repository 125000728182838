.container {
    background: #007da0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 40px;
    width: 980px;
}

.header_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header_left_title {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 5px;
}

.header_left_desc {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffff;
    white-space: pre-line;
    text-align: left;
}

.header_right {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header_right_content {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
}

.body {
    width: 930px;
    background: #ffffff;
    border-radius: 14px;
    height: 373px;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.title {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    color: #ffffff;
}

.subTitle {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #ffffff;
}

.footer {
}

.spacer {
    //flex: 1;
}

.policy {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #ffffff;
    width: 968px;
}

.cart_container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.cart_total_value {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: #0fd179;
}

.cart_total_text {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(69, 74, 86, 1);
    margin-top: 20px;
    margin-bottom: 5px;
}

.back_button {
    composes: hollowButton from "../style/button.scss";
    height: 40px;
    width: 124px;
    margin-bottom: 5px;
}

.paypal_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paypal_shield {
    margin-top: 30px;
}

.paypal_desc {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 350px;
    margin: 15px 0 40px 0;
}

.paypal_button {
    composes: greenButton from "../style/button.scss";
    height: 50px;
    width: 350px;
}

.privacypolicyButton {
    composes: linkButton from "../style/button.scss";
    composes: secondaryFont from "../style/typography.scss";
    font-size: 10px;
    font-weight: 400;
    margin: 0 3px;
}

.termsButton {
    composes: linkButton from "../style/button.scss";
    composes: secondaryFont from "../style/typography.scss";
    font-size: 10px;
    font-weight: 400;
    margin: 0 3px;
}

.cc_tab_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cc_tab_text {
    composes: secondaryFont from "../style/typography.scss";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(74, 79, 91, 1);
    margin-right: 10px;
}

.tab {
    display: flex;
    justify-content: stretch;
    background: linear-gradient(130deg, #e9ebf1 100%, #f1f2f7 100%);
    border-radius: 14px 14px 0 0;
}

.tabItemRoot {
    flex: 1;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
}

.tabItemRoot.tabItemSelected {
    background: #ffffff;
    border-radius: 14px 14px 0 0;
}
