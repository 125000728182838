.errorModal {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    width: 400px;
    padding: 25px;
    align-items: center;
}

.main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.errorImage {
}

.errorTextWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.title {
    composes: mainFont from "../../style/typography.scss";
    font-size: 24px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 20px;
}

.content {
    composes: secondaryFont from "../../style/typography.scss";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #777777;
}

.closeButton {
    composes: blueButton from "../../style/button.scss";
    composes: mainFont from "../../style/typography.scss";
    height: 35px;
    width: 145px;
    padding: 0;
}

.footer {
    margin-top: 25px;
    align-items: center;
    justify-content: center;
}
