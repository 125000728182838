.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
    background-image: url(../assets/Background.png);
}

.title_container {
    border-radius: 14px;
    background: #ffffff;
    width: 930px;
    box-sizing: border-box;
    padding: 20px;
    margin: 30px 0;
}

.title {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-size: 26px;
    font-weight: 600;
    line-height: 36px;
}

.content_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 14px;
    background: #ffffff;
    width: 930px;
    box-sizing: border-box;
    padding: 30px;
    padding-bottom: 60px;
    margin-bottom: 20px;
}

.message {
    composes: secondaryFont from "../style/typography.scss";
    color: #757e95;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}
