.mainFont {
    font-family: "Roboto", Arial, sans-serif;
    transform: rotateX(0);
}

.secondaryFont {
    font-family: "Open Sans", sans-serif;
    transform: rotateX(0);
}

.logoFont {
    font-family: "Segoe UI";
    transform: rotateX(0);
}
